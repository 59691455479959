import React, {Component} from 'react';
import {Auth} from "../../shared/services/aws";
import CustomForm from "../../shared/components/form/CustomForm";
import {ResetPasswordForm} from "../../shared/components/form/LoginForm";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: null,
      formInfo: null,
      isResetSuccessful: false,
      properties: {
        email: '',
        newPassword: '',
        verificationCode: '',
      },
      isLoading: false
    };
  }

  // ----------------------
  // Lifecycle
  // ----------------------

  componentDidMount() {
    this.setState(state => ({
      properties: {
        ...state.properties,
        email: this.props.match.params.email.toLowerCase(),
        verificationCode: this.props.match.params.verificationCode,
      },
    }));
  }

  catchForgotPasswordError = (forgotPasswordError) => {
    const getFormErrors = () => {
      switch (forgotPasswordError.code) {
        case 'ExpiredCodeException':
          Auth.forgotPassword(this.state.properties.email);
          return [{
            key: '0',
            message: 'Email link expired, Sending new code. Check your email and click on reset password link again'
          }];
        default:
          return [{key: '0', message: forgotPasswordError.message}];
      }
    };
    this.setState({
      formErrors: getFormErrors(),
      isLoading: false
    });
    return null;
  };

  handleSubmit = (form) => {
    this.setState({
      isLoading: true
    });

    Auth.forgotPasswordSubmit(
      this.state.properties.email,
      this.state.properties.verificationCode,
      form.new_password,
    )
      .then(() => {
        this.setState({
          formInfo: [
            {
              key: '0',
              message: 'Your password has been reset.',
            },
          ],
          isResetSuccessful: true,
          isLoading: false
        });
      })
      .catch(error => {
        console.log('error', error);
        this.catchForgotPasswordError(error)
      });

  };

  render() {
    return (
      <div className="login">
        <div className="login-form column">
          {this.state.formErrors && (
            <div className="alert alert-danger">
              <ul className="mb-0 pl-3">
                {this.state.formErrors.map((error) => {
                  return <li key={error.key}>{error.message}</li>;
                })}
              </ul>
            </div>
          )}
          {this.state.formInfo && (
            <div className="alert alert-primary">
              <ul className="mb-0 pl-3">
                {this.state.formInfo.map((info) => {
                  return <li key={info.key}>{info.message}</li>;
                })}
              </ul>
            </div>
          )}
          {!this.state.isResetSuccessful ? (
            <CustomForm
              config={ResetPasswordForm}
              saveLabel="Reset Password"
              onSubmit={(form) => this.handleSubmit(form)}
              isLoading={this.state.isLoading}
            />
          ) : (
            <div className="column">
              <div className="login-actions">
                <button
                  className="button primary"
                  onClick={() => this.props.history.push('/login')}
                >
                  Login
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ResetPassword;
