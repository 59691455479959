import axios from 'axios';
import {Auth} from "./aws";

const ApiService = axios.create({
  baseURL: '/',
  headers: {
    common: {
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
});

const ApiGet = (url) =>
  ApiService.get(url)
    .then(({data}) => data.data);
const ApiGetRaw = (url) =>
  ApiService.get(url)
    .then(({data}) => data);

const ApiGetCommon = (url) =>
  ApiService.get(url)
    .then(({data}) => data);

const ApiGetAdmin = (url) =>
  ApiService.get('/api/v1/admin' + url)
    .then(({data}) => data.data);

const ApiPost = (url, body) =>
  ApiService.post(url, body)
    .then(({data}) => data.data);

const ApiPostRaw = (url, body) =>
  ApiService.post(url, body)
    .then(({data}) => data);

const ApiPatch = (url, body) =>
  ApiService.patch(url, body)
    .then(({data}) => data);

const ApiDelete = (url, body = {}) =>
  ApiService.delete(url, {data: body})
    .then(({data}) => data);

const ApiPostAdmin = (url, body) =>
  ApiService.post('/api/v1/admin' + url, body)
    .then(({data}) => data);

const ApiPatchAdmin = (url, guid, body) =>
  ApiService.patch(`/api/v1/admin${url}/${guid}`, body)
    .then(({data}) => data);

const ApiDeleteAdmin = (url, guid, body) =>
  ApiService.delete(`/api/v1/admin${url}/${guid}`, body)
    .then(({data}) => data);

ApiService.interceptors.request.use((request) => {
  if (request.url === '/api/v1/support-request' || request.url === '/api/v1/demo-request') return request
  return Auth.currentSession()
    .then(res => {
      request.headers['authorization'] = res.accessToken.jwtToken;
      return request
    })
});

/**
 * Add authorization header to request; API paths beginning with /a/ require authentication
 */

ApiService.interceptors.response.use((response) => {
  return response
}, (error) => {

  if (error === 'No current user') {
    location.href = '/login'
    return
  }

  let errorResponse = error.response

  if (errorResponse.status === 401 && errorResponse.config && !errorResponse.config.__isRetryRequest) {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(res => ApiService.post('/api/v1/validate', { jwt: res.accessToken.jwtToken })
          .then(() => {
            errorResponse.config.__isRetryRequest = true;
            resolve(ApiService(errorResponse.config))
          })
          .catch(() => {
            reject(errorResponse)
          }))
        .catch(() => {
          reject(errorResponse)
        })
    })
  } else if (errorResponse.status === 401) {
    location.href = '/login'
  }

  return Promise.reject(errorResponse)

});

export default ApiService;

export {
  ApiService,
  ApiGet,
  ApiGetRaw,
  ApiPost,
  ApiPostRaw,
  ApiGetAdmin,
  ApiPostAdmin,
  ApiPatchAdmin,
  ApiDeleteAdmin,
  ApiGetCommon,
  ApiPatch,
  ApiDelete
}
