import React from 'react';
import classNames from 'classnames';

const SeparatorHeading = ({heading, large, stroked}) => (
  <div className={classNames('separator-heading', {'large': large, 'stroked' : stroked })}>
    <div className='separator-line'/>
    <div className='separator-text'>{heading}</div>
    <div className='separator-line'/>
  </div>
);

export default SeparatorHeading