__webpack_public_path__ = '/';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import PublicApp from './PublicApp';
import store from './store/index';
import './scss/style.scss';
import {initAmplify} from "../shared/services/aws";

initAmplify({
  Auth: {
    identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
    region: process.env.AWS_REGION,
    userPoolId: process.env.COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.COGNITO_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
  },
  Storage: {
    bucket: process.env.S3_BUCKET,
    region: process.env.AWS_REGION,
  },
}).then(() => {


});

ReactDOM.render(
  <Provider store={store}>
    <PublicApp />
    {/*
    <StripeProvider apiKey="pk_test_s7KtHL48dVApEmINn7QeHmn8">
    </StripeProvider>
    */}
  </Provider>,
  document.getElementById('public-app'),
);