import notificationCommitTypes from './commit-types';
import {ApiPostRaw} from "../../../shared/services/api-service";

const notificationsActionsCreator = {

  addNotification(notification) {
    return (dispatch) => {
      dispatch({
        notification,
          type: notificationCommitTypes.ADD_NOTIFICATION_TO_QUEUE,
      });
      dispatch(this.martNotificationForRemove(notification.id))
    };
  },

  addSavedSuccessfully(message) {
    return (dispatch) => {
      let id = Date.now();

      dispatch(this.addNotification({
        id,
        type: 'success',
        content: message || 'Saved',
      }));
    }
  },

  addError(message) {
    return (dispatch) => {
      let id = Date.now();

      dispatch(this.addNotification({
        id,
        type: 'error',
        content: message || 'An error has occurred',
      }));
    }
  },

  removeNotification(notificationId) {
    return (dispatch) => {
      setTimeout(() => {
        dispatch({
          notificationId,
          type: notificationCommitTypes.REMOVE_NOTIFICATION_TO_QUEUE
        })
      }, 300)
    }
  },

  martNotificationForRemove(notificationId) {
    return (dispatch) => {
      setTimeout(() => {
        dispatch({
          notificationId,
          type: notificationCommitTypes.MARK_NOTIFICATION_FOR_REMOVE
        });
        dispatch(this.removeNotification(notificationId))
      }, 3000)
    }
  },

};

export default notificationsActionsCreator;