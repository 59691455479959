import departmentSettingsActionsCreators from './department-settings';
import departmentSettingsCommitTypes from '../commit-types';
import ApiService from '../../../../shared/services/api-service';
import commonActionCreators from '../../../../shared/store/common/action-creators';
import notificationsActionsCreator from '../../notifications/action-creators';

const notificationsActionCreators = {
  editNotification() {
    return {
      isEditingNotification: true,
      type: departmentSettingsCommitTypes.SET_IS_EDITING_NOTIFICATION,
    };
  },
  closeEditNotification() {
    return {
      isEditingNotification: false,
      type: departmentSettingsCommitTypes.SET_IS_EDITING_NOTIFICATION,
    };
  },
  fetchNotifications() {
    return async (dispatch) => {
      dispatch(departmentSettingsActionsCreators.setIsLoading('fetchNotifications'));
      const data = await ApiService.get('/api/v1/notifications')
        .then(response => response.data)
        .catch(error => ({ error }));
      dispatch(departmentSettingsActionsCreators.setIsLoading('fetchNotifications'));
      if (data.error) {
        dispatch(commonActionCreators.handleApiError(data.error));
      } else {
        dispatch(this.fetchNotificationsSuccess(data));
      }
    };
  },
  fetchNotificationsSuccess(notificationsResponse) {
    return (dispatch) => {
      dispatch(this.pushNotifications(notificationsResponse.data));
    };
  },
  pushNotifications(notifications) {
    return {
      notifications,
      type: departmentSettingsCommitTypes.PUSH_NOTIFICATIONS,
    };
  },
  setSelectedNotification(selectedNotificationGuid) {
    return {
      selectedNotificationGuid,
      type: departmentSettingsCommitTypes.SET_SELECTED_NOTIFICATION,
    };
  },
  replaceNotification(notification) {
    return {
      notification,
      type: departmentSettingsCommitTypes.REPLACE_NOTIFICATION,
    };
  },
  updateDistribution(formData) {
    const patchData = {
      data: formData.distribution,
      patched: 'distribution',
    };
    return async (dispatch) => {
      dispatch(this.setIsLoading('updateDistribution'));
      const data = await ApiService.patch(`/api/v1/notifications/${formData.notificationGuid}`, patchData)
        .then(response => response.data)
        .catch(error => ({ error }));
      dispatch(this.setIsLoading('updateDistribution'));
      if (data.error) {
        dispatch(commonActionCreators.handleApiError(data.error));
      } else {
        dispatch(this.updateDistributionSuccess(data));
      }
    };
  },
  updateDistributionSuccess(updated) {
    return (dispatch) => {
      dispatch(this.replaceNotification(updated.data));
      dispatch(notificationsActionsCreator.addSavedSuccessfully('Distribution updated'))
    };
  },
  updateNotification(notificationGuid, formData) {
    const patchData = {
      data: formData,
      patched: 'details',
    };
    return async (dispatch) => {
      dispatch(this.setIsLoading('updateNotification'));
      const data = await ApiService.patch(`/api/v1/notifications/${notificationGuid}`, patchData)
        .then(response => response.data)
        .catch(error => ({ error }));
      dispatch(this.setIsLoading('updateNotification'));
      if (data.error) {
        dispatch(commonActionCreators.handleApiError(data.error));
      } else {
        dispatch(this.updateNotificationSuccess(data));
      }
    };
  },
  updateNotificationSuccess(updated) {
    return (dispatch) => {
      dispatch(this.replaceNotification(updated.data));
      dispatch(this.closeEditNotification());
      dispatch(notificationsActionsCreator.addSavedSuccessfully('Notification updated'))
    };
  },
};

export default notificationsActionCreators;
