import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Footer from './components/Footer';
import PublicNavBar from './components/PublicNavBar';
import PublicRoute from './components/PublicRoute';
import Faq from './components/Faq';
import PasswordResetContainer from './components/PasswordResetContainer';
import Home from './components/Home';
import OurTeam from './components/OurTeam';
import Login from './components/Login';
import NoMatch from '../shared/components/NoMatch';
import Pricing from './components/Pricing';
import PrivacyPolicy from './components/PrivacyPolicy';
import ProductOverview from './components/ProductOverview';
import RequestDemo from './components/RequestDemo';
import CustomerSupport from "./components/CustomerSupport";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiError: null,
      isErrorModalOpen: false,
    };
    this.closeErrorModal = this.closeErrorModal.bind(this);
  }

  // ----------------------
  // Lifecycle
  // ----------------------

  componentDidUpdate() {
    if (!!this.props.apiErrors && !this.state.isErrorModalOpen) {
      this.openErrorModal(this.props.apiErrors[0]);
      // this.props.clearApiError();
    }
  }

  // ----------------------
  // Computed
  // ----------------------

  get apiErrorData() {
    return this.state.apiError ? JSON.stringify(this.state.apiError.data) : '';
  }

  get apiErrorMessage() {
    return this.state.apiError ? this.state.apiError.message : '';
  }

  get apiErrorType() {
    return this.state.apiError ? this.state.apiError.type : '';
  }

  // ----------------------
  // Methods
  // ----------------------

  closeErrorModal() {
    this.setState({ isErrorModalOpen: false });
  }

  openErrorModal(apiError) {
    this.setState({
      apiError,
      isErrorModalOpen: true,
    });
  }

  // ----------------------
  // Render
  // ----------------------

  render() {
    return (
      <Router>
        <Fragment>
          <PublicNavBar />
          <div className="main-content">
            <Switch>
              {/*
                Authentication Routes
              */}

              <PublicRoute component={PasswordResetContainer} path="/forgot-password" />
              <PublicRoute component={Login} path="/login" />

              {/*
                Public Content Routes
              */}

              <PublicRoute path="/" exact component={Home} />
              <PublicRoute path="/FAQ" component={Faq} />
              <PublicRoute path="/pricing" component={Pricing} />
              <PublicRoute path="/our-team" component={OurTeam} />
              <PublicRoute path="/privacy-policy" component={PrivacyPolicy} />
              <PublicRoute path="/product-overview" component={ProductOverview} />
              <PublicRoute path="/request-demo" component={RequestDemo} />
              <PublicRoute path="/customer-support" component={CustomerSupport} />
              <Route component={NoMatch} />
            </Switch>
          </div>
          <Footer />
          <Modal
            isOpen={this.state.isErrorModalOpen}
            toggle={this.closeErrorModal}
            className={this.props.className}
          >
            <ModalHeader toggle={this.closeErrorModal}>{this.apiErrorType}</ModalHeader>
            <ModalBody>
              <p>{this.apiErrorMessage}</p>
              <pre>
                <code>{this.apiErrorData}</code>
              </pre>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.closeErrorModal}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </Fragment>
      </Router>
    );
  }
}

// ----------------------
// Redux mapping
// ----------------------

const mapStateToProps = (state) => {
  return { apiErrors: state.common.apiErrors };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // clearApiError: () => {
    //   dispatch(commonActionCreators.clearApiError());
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
