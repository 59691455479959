/**
 * Utility class to format error responses from axios API requests
 */

export default class AxiosError {
  /**
   * Unwrap API 422 response data into something more useful for mapping to strings
   *   for use in alert elements
   * @param {Object} error API 422 error response object
   */
  static unwrap422Errors(errors) {
    return Object.keys(errors).reduce((accumulator, key) => {
      return [
        ...accumulator,
        { key, errors: errors[key] },
      ];
    }, []);
  }

  static format(error) {
    const common = {
      data: {
        status: error.status,
        url: error.config.url,
      },
      message:
        'Please report this error and include this error type.',
      method: error.config.method,
    };
    switch (error.status) {
      case 400:
        return {
          ...common,
          message: error.data.error,
          type: error.data.title || 'Bad Request',
        };
      case 401:
        return {
          ...common,
          message: 'Your session has expired. Please login again.',
          type: 'Unauthenticated',
        };
      case 403:
        return {
          ...common,
          data: {
            ...common.data,
            method: error.config.method,
          },
          message: 'You are not authorized for the request.',
          type: 'Forbidden',
        };
      case 404:
        return {
          ...common,
          message:
            'Contact DPW System Administrator and include the address bar URL and the data below:',
          type: 'Resource Not Found',
        };
      case 405:
        return {
          ...common,
          data: {
            ...common.data,
            method: error.config.method,
          },
          message:
            'Please report this error and include the address bar URL and the data below:',
          type: 'Method Not Allowed',
        };
      case 406:
        return {
          ...common,
          message: error.data.error,
          type: 'Not Acceptable',
        };
      case 422:
        return {
          ...common,
          data: {
            ...error.data.errors,
          },
          message:
            'Contact DPW System Administrator',
          type: 'Invalid Action',
        };
      case 500:
        return {
          ...common,
          data: {
            ...common.data,
            exception: error.data.exception,
            file: error.data.file,
            line: error.data.line,
            message: error.data.message,
          },
          type: 'Internal Server Error',
        };
      default:
        return {
          ...common,
          type: 'Unknown',
        };
    }
  }
}
