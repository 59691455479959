const ReducerUtilities = {
  findIndexByGuid(guid, array) {
    return array.findIndex(obj => obj.guid === guid);
  },
  removeModelFromList(guid, modelList) {
    if (!modelList) return []
    const index = this.findIndexByGuid(guid, modelList);
    if (index !== -1) {
      modelList.splice(index, 1);
    }
    return modelList;
  },
  replaceModelInList(modelItem, modelList) {
    if(!modelList) return null; //added for use case when training phase reports are not loaded but reports are

    const index = this.findIndexByGuid(modelItem.guid, modelList);
    if (index === -1) {
      console.error('Item not found in list');
      return modelList;
    }
    // Clone and return with replacement
    const updatedList = [...modelList];
    updatedList[index] = modelItem;
    return updatedList;
  },
  replaceModelsInList(modelItems, modelList) {
    let list = [...modelList];
    modelItems.forEach((item) => {
      list = this.replaceModelInList(item, list);
    });
    return list;
  },
  updateIsLoading(submittedAction, loadingActions) {
    const add = (toAdd, actions) => [...actions, toAdd];
    const remove = (toRemove, actions) => actions.filter(action => action !== toRemove);
    return loadingActions.includes(submittedAction)
      ? remove(submittedAction, loadingActions) : add(submittedAction, loadingActions);
  },
};

export default ReducerUtilities;
