import isEmailValid from "validator/lib/isEmail";

const validateRequired = (field) => {
  return {
    feedback: `${field} is required`,
    test(value) {
      return value !== false ? !!value : true;
    }
  }
};

const validateNumber = (field) => {
  return {
    feedback: `${field} is must be a number`,
    test(value) {
      if (value === '') {
        return true;
      }
      return !!value.match(/^\d+$/)
    }
  }
};

const validateRequiredIfNot = (field, otherField) => {
  return {
    feedback: `${field} is required`,
    test(value, form) {
      return !form[otherField] ? !!value : true;
    }
  }
};

const validateEmail = {
  feedback: 'Email format not valid',
  test(value) {
    return isEmailValid(value);
  },
};


const validatePhoneNumber = {
  feedback: 'Phone format is not valid',
  test(value) {
    if(value===''){
      return true;
    }
    return !!value.match(/^\(?[2-9]\d{2}\)?[ -]?[2-9]\d{2}-?\d{4}$/);
  },
};

const validatePassingScore = {
  feedback: 'Passing score must be between 10 and 100',
  test(value) {
    if (value === '') {
      return true;
    }
    let val = parseInt(value);
    return val && val >= 10 && val <= 100
  },
};

export {
  validateRequired,
  validateRequiredIfNot,
  validateEmail,
  validatePhoneNumber,
  validatePassingScore,
  validateNumber
}
