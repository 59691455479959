import departmentSettings from './department-settings';
import notificationsActionsCreators from './notifications';
import tasksActionsCreators from '../../tasks/action-creators/tasks'

const departmentSettingsActionsCreators = {
  ...departmentSettings,
  ...notificationsActionsCreators,
  ...tasksActionsCreators
};

export default departmentSettingsActionsCreators;
