const validateForm = (config, formValues) => {
  let fields = config.fields;
  let formValid = true;
  let fieldsValidation = {};

  Object.keys(fields).forEach(fieldKey => {
    let field = fields[fieldKey];

    field.rules && field.rules.forEach(rule => {
      if (!rule.test(formValues[fieldKey], formValues) && !fieldsValidation[fieldKey]) {
        formValid = false;
        fieldsValidation[fieldKey] = rule.feedback;
      }
    })
  });

  return {
    formValid,
    fieldsValidation
  }
};

export {
  validateForm
}
