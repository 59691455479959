import { Formik } from 'formik';
import React from 'react';
import { Form, FormFeedback, FormGroup, Label, Input } from 'reactstrap';

// eslint-disable-next-line arrow-body-style
const CustomerSupportForm = (props) => {
  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={props.validationSchema}
      onSubmit={props.onSubmit}
      render={({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        touched,
        values,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name">Name *</Label>
            <Input
              className={`${touched.name && errors.name && 'is-invalid'}`}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              value={values.name}
            />
            <FormFeedback>{errors.name}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="email">Email *</Label>
            <Input
              className={`${touched.email && errors.email && 'is-invalid'}`}
              onChange={handleChange}
              onBlur={handleBlur}
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={values.email}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="message">Message *</Label>
            <Input
              className={`${touched.message && errors.message && 'is-invalid'}`}
              onChange={handleChange}
              onBlur={handleBlur}
              type="textarea"
              name="message"
              id="message"
              placeholder="Message"
              value={values.message}
            />
            <FormFeedback>{errors.message}</FormFeedback>
          </FormGroup>
          <div style={{ textAlign: 'right' }}>
            <button className="button primary" disabled={!isValid}>
              Submit
            </button>
          </div>
        </Form>
      )}
    />
  );
};

export default CustomerSupportForm;
