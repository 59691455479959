import ApiService from '../../../../shared/services/api-service';
import commonActionCreators from '../../../../shared/store/common/action-creators';
import notificationsActions from '../../notifications/action-creators';
import tasksCommitTypes from '../commit-types';

const tasksActionCreators = {
  addTask() {
    return {
      isAddingTask: true,
      type: tasksCommitTypes.SET_IS_ADDING_TASK,
    };
  },
  closeAddTask() {
    return {
      isAddingTask: false,
      type: tasksCommitTypes.SET_IS_ADDING_TASK,
    };
  },
  closeEditTask() {
    return {
      isEditingTask: null,
      type: tasksCommitTypes.SET_IS_EDITING_TASK,
    };
  },
  deleteTask({ guid }) {
    return async (dispatch) => {
      dispatch(this.setIsLoading('deleteTask'));
      const data = await ApiService.delete(`/api/v1/tasks/${guid}`)
        .then(response => response.data)
        .catch(error => ({ error }));

      dispatch(this.setIsLoading('deleteTask'));
      if (data.error) {
        dispatch(commonActionCreators.handleApiError(data.error));
      } else {
        dispatch(this.removeTask(data.data));
        dispatch(notificationsActions.addSavedSuccessfully('Task removed'));
      }
    };
  },
  editTask(taskGuid) {
    return {
      isEditingTask: taskGuid,
      type: tasksCommitTypes.SET_IS_EDITING_TASK,
    };
  },
  fetchTasks() {
    return async (dispatch) => {
      dispatch(this.setIsLoading('fetchTasks'));
      const data = await ApiService.get('/api/v1/tasks')
        .then(response => response.data)
        .catch(error => ({ error }));
      dispatch(this.setIsLoading('fetchTasks'));
      if (data.error) {
        dispatch(commonActionCreators.handleApiError(data.error));
      } else {
        dispatch(this.fetchTasksSuccess(data));
      }
    };
  },
  fetchTasksSuccess(tasksResponse) {
    return (dispatch) => {
      dispatch(this.pushTasks(tasksResponse.data));
    };
  },
  loadTaskContainer() {
    return (dispatch) => {
      dispatch(this.fetchTasks());
    };
  },
  pushTask(task) {
    return {
      task,
      type: tasksCommitTypes.PUSH_TASK,
    };
  },
  pushTasks(tasks) {
    return {
      tasks,
      type: tasksCommitTypes.PUSH_TASKS,
    };
  },
  removeTask(task) {
    return {
      task,
      type: tasksCommitTypes.REMOVE_TASK,
    };
  },
  replaceTask(task) {
    return {
      task,
      type: tasksCommitTypes.REPLACE_TASK,
    };
  },
  setIsLoading(isLoading) {
    return {
      isLoading,
      type: tasksCommitTypes.SET_TASK_IS_LOADING,
    };
  },
  unmountTaskContainer() {
    return {
      type: tasksCommitTypes.RESET_TASK_STATE,
    };
  },
  updateTask(formData) {
    const patch = { ...formData };
    // Remove redundant
    delete patch.taskGuid;
    return async (dispatch) => {
      dispatch(this.setIsLoading('updateTask'));
      const data = await ApiService.put(`/api/v1/tasks/${formData.taskGuid}`, patch)
        .then(response => response.data)
        .catch(error => ({ error }));
      dispatch(this.setIsLoading('updateTask'));
      if (data.error) {
        dispatch(commonActionCreators.handleApiError(data.error));
      } else {
        dispatch(this.updateTaskSuccess(data));
      }
    };
  },
  updateTaskSuccess(taskResponse) {
    return async (dispatch) => {
      dispatch(notificationsActions.addSavedSuccessfully('Task Updated'));
      dispatch(this.closeEditTask());
      dispatch(this.replaceTask(taskResponse.data));
    };
  },
};

export default tasksActionCreators;
