import { combineReducers } from 'redux';
import commonReducers from '../../shared/store/common/reducers';
import publicReducers from './public/reducers';

const rootReducers = combineReducers({
  common: commonReducers,
  public: publicReducers,
});

export default rootReducers;
