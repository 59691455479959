import {CrossStorageClient} from 'cross-storage'

const storage = new CrossStorageClient('https://shared.departmentware.com/shared.html');

const initSharedStorage = () => {
  return storage.onConnect()
    .then(() => {
      return storage.get('sharedStorage');
    })
    .then((res) => {
      try {
        localStorage.clear();
        let sharedStorage = JSON.parse(res);
        Object.keys(sharedStorage)
          .forEach((key) => {
            localStorage.setItem(key, sharedStorage[key])
          })
      } catch (e) {
      }
    })
    .catch(() => {
      return Promise.resolve()
    })
};

const updateSharedStorage = () => {
  return storage.onConnect()
    .then(() => {
      return storage.set('sharedStorage', JSON.stringify({...localStorage}));
    })
    .catch(() => {
      return Promise.resolve()
    });
};

const clearSharedStorage = () => {
  return storage.onConnect()
    .then(() => {
      localStorage.clear()
      return storage.set('sharedStorage', JSON.stringify({}));
    })
    .catch(() => {
      return Promise.resolve()
    });
};
export {
  initSharedStorage,
  updateSharedStorage,
  clearSharedStorage
}
