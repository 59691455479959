import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import AnimateInViewport from '../../shared/ui/AnimateInViewport';
import SeparatorHeading from '../../shared/ui/SeparatorHeading';

const included = [
  {
    icon: 'fa-id-badge',
    title: 'Field Training Officer',
    text: 'FTO enters recruits training progress',
  },
  {
    icon: 'fa-certificate',
    title: 'Officer in Training',
    text: 'OIT reviews training progress and signs off.',
  },
  {
    icon: 'fa-bell',
    className: 'final-step',
    title: 'Command Staff',
    text: 'Automated Reports & Notifications',
  },
  {
    icon: 'fa-check-square',
    className: 'final-step',
    title: 'Administrator',
    text: 'Verifies details',
  },
];

const benefits = [
  {
    icon: 'fa-cloud',
    title: 'Cloud-based',
    text:
      'Accessible anywhere you have internet access, on any device. No complicated software to install or maintain.',
  },
  {
    icon: 'fa-lock',
    title: 'Encrypted Data',
    text:
      'Data is fully encrypted, end-to-end. Your information is protected and controls prevent unauthorized access.',
  },
  {
    icon: 'fa-cloud',
    title: 'Customizable',
    text:
      'Flexible software, just clicks to customize. Easy to integrate with existing systems and reporting platforms.',
  },
];

const intro = {
  title: 'FTO Automator',
  text:
    'See how departments across the country are leveraging Departmentware to improve their operations while simultaneously mitigating risk and lowering costs.',
};

const office = {
  title: 'Your mobile office',
  caption: 'Why take your paperwork home, when you can do it at the office?',
  text:
    'Departmentware software was designed to operate in both the desktop and mobile environments. ' +
    'Whether you use a desktop, laptop, tablet or smart phone, you can complete DOR’s and view data provided you have an internet connection.',
};

const Home = () => (
  <Fragment>
    <Container fluid className="home-hero m-0">
      <div className="container home-hero-text">
        <h1 className="home-hero-title">Departmentware</h1>
        <h6 className="home-hero-description">
          Better software for public safety
        </h6>
        <div className="lead">
          <Link className="button primary" to="/product-overview">
            <span>Product Overview</span>&nbsp;
            <i className="fa fa-chevron-right" />
          </Link>
        </div>
      </div>
    </Container>

    <SeparatorHeading heading="INTRODUCING" />
    <div className="fto-automator">
      <Container className="fto-automator-content">
        <div className="fto-laptop">
          <img
            src="/images/laptop-screen.jpg"
            alt={intro.title}
            className="img-fluid"
          />
        </div>
        <div className="fto-description">
          <h4>{intro.title}</h4>
          <div className="fto-intro">{intro.text}</div>
          <Link className="button primary" to="/request-demo">
            <span>Request Demo</span>&nbsp;
            <i className="fa fa-chevron-right" />
          </Link>
        </div>
      </Container>
      <Container>
        <div className="includes">
          {included.map((include, index) => (
            <AnimateInViewport key={include.title} delay={1000 * index}>
              <div
                className={`includes-item ${include.className}`}
                style={{ zIndex: included.length - index }}
              >
                <div className="includes-icon">
                  <i className={`fa ${include.icon}`} />
                </div>
                <div className="includes-content">
                  <div className="includes-title">{include.title}</div>
                  <div className="includes-text">{include.text}</div>
                </div>
              </div>
            </AnimateInViewport>
          ))}
        </div>
      </Container>
    </div>
    <SeparatorHeading heading="OUR BENEFITS" />
    <div className="text-center benefits">
      {benefits.map(benefit => (
        <div key={benefit.title} className="benefit">
          <div className="benefit-icon">
            <i className={`fa ${benefit.icon}`} />
          </div>
          <h5 className="benefit-title">{benefit.title}</h5>
          <p className="benefit-text">{benefit.text}</p>
        </div>
      ))}
    </div>
    <SeparatorHeading heading="MOBILE OFFICE" />
    <div className="mobile-office">
      <Container className="mobile-office-content">
        <div className="mobile-office-desc">
          <h4 className="mobile-office-title">{office.title}</h4>
          <h6 className="mobile-office-caption">{office.caption}</h6>
          <p className="mobile-office-text">{office.text}</p>
        </div>
        <div>
          <img
            src="/images/police-cruiser.jpg"
            alt={office.title}
            className="img-fluid"
          />
        </div>
      </Container>
    </div>
    <SeparatorHeading heading="TESTIMONIALS" />
    <div className="testimonials">
      <Container>
        <div className="testimonials-container">
          <div className="testimonial">
            <div className="quote">
              <i>"Departmentware’s innovative cloud based Field Training Program is a must for 21st century policing.  In addition to its accessibility, customization, ease of use, cloud based storage, and cost effectiveness; it helps meet the needs of all personnel from trainee to command staff by placing critical information regarding field training performance at every user’s fingertips.  This product is a winner"</i>
            </div>
            <div className="text-center signature"><b>JR Wilson </b><span className="text-muted">Chief of Police / Asst. City Manager</span></div>
            <div className="text-center"><a href="https://hopearkansas.net/pview.aspx?id=6254&catid=580">Hope, Arkansas Police Department</a></div>
          </div>
        </div>
      </Container>
    </div>
    <SeparatorHeading heading="PARTNER" />
    <div className="partner">
      <Container>
        <div className="logo-partner">
          <img src="/images/ksa_logo.svg" />
          <div className="name">Kaminsky, Sullenberger & Associates</div>
        </div>
        <p className="partner-text">
          Kaminsky, Sullenberger & Associates (formally known as Kaminsky & Associates) is the foremost industry leader providing FTO (Field Training Officer, formally known as the FTEP or Field Training and Evaluation Program) related training and consulting in the nation. Having more than 40 years of experience, Kaminsky, Sullenberger & Associates offers a variety of FTO related services; from assisting in the development of a legally defensible FTO program to basic training for FTOs to advanced training for experienced FTOs and the administration and management of successful FTO Programs.
        </p>
        <p className="partner-text">
          For further information please visit us at <a href="http://www.ksa-fto.com">www.ksa-fto.com</a> or call 303/358-9780
        </p>
      </Container>
    </div>
  </Fragment>
);

export default Home;
