import React, {Component, Fragment} from 'react';
import './LoadingOverlay.scss';
import {CSSTransition} from "react-transition-group";
import classNames from 'classnames';

class LoadingOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false
    }
  }

  setLoaded() {
    this.setState({
      isLoaded: true
    })
  }

  render() {
    let {isLoading, children} = this.props;

    return (
      <Fragment>
        <CSSTransition classNames="loading" timeout={500} in={isLoading} unmountOnExit  onExited={() => {this.setLoaded()}}>
          <div className={classNames("loading-overlay", {
            fullscreen: this.props.fullscreen
          })}>
            <div className="spinner">
              <div className="dot1"></div>
              <div className="dot2"></div>
            </div>
          </div>
        </CSSTransition>
        {children}
      </Fragment>
    )
  }
}

export default LoadingOverlay;

