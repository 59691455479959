import React, {Component} from 'react';
import {connect} from 'react-redux';


function mapStateToProps(state) {
  return {
    formTypes: state.common.formTypes.map(t => t.short)
  };
}

class FormTypePicker extends Component {
  render() {
    return (

      <select
        style={{width: '100%'}}
        className='form-control'
        value={this.props.value}
        onChange={(e) => this.props.onChange(e.target.value)}
        onBlur={(e) => this.props.onBlur(e.target.value)}>
        {!this.props.value && <option value="" disabled/>}
        {this.props.formTypes.map((formType) => (
          <option
            key={formType}
            value={formType}>
            {formType}
          </option>
        ))}
      </select>

    );
  }
}

export default connect(
  mapStateToProps,
)(FormTypePicker);
