import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {AmplifyAuth} from '../../shared/services/aws';
import commonActionCreators from '../../shared/store/common/action-creators';

class PublicRoute extends Component {
  constructor(props) {
    super(props);
    const inheritedProps = { ...props };
    delete inheritedProps.component;
    this.state = { inheritedProps };
  }

  // ----------------------
  // Lifecycle
  // ----------------------

  componentDidMount() {
    // TODO Promise resolves to boolean, so trigger overlay to hide UI bounce if necessary
    this.getSessionStatus()
      .then((status) => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.isRedirecting && nextProps.path !== this.props.path) {
      // TODO Promise resolves to boolean, so trigger overlay to hide UI bounce if necessary
      this.getSessionStatus()
        .then((status) => {
          return true;
        })
        .catch((error) => {
          return false;
        });
    }
    if (nextProps.path === this.props.redirectingTo) {
      this.props.setRedirectingTo(null);
    }
  }

  // ----------------------
  // Methods
  // ----------------------

  getCurrentUserToken() {
    return AmplifyAuth
      .then(Auth => Auth.currentSession())
      .then(res => res.accessToken.jwtToken)
  }

  getUserContext(currentUserToken) {
    if (!currentUserToken) {
      return Promise.resolve(null);
    }
    if (this.props.userContext.isAuthenticated) {
      return Promise.resolve(this.props.userContext);
    }
    return this.props.fetchUserContext(currentUserToken);
  }

  //  This is a fallback for the setup that typically happens on successful login. Repopulates
  //    the Redux store in the case of User navigating directly to an application route.
  //
  //    1. Attempt to restore authentication by getting the current User token from local storage
  //    2. Attempt to restore authorization by getting the userContext
  getSessionStatus() {
    return new Promise(async (resolve, reject) => {
      const currentUserToken = await this.getCurrentUserToken();
      const userContext = await this.getUserContext(currentUserToken);
      this.props.setUserContext(userContext);
      if (!currentUserToken) {
        return reject(new Error('NOT_AUTHENTICATED'));
      }
      if (!userContext) {
        return reject(new Error('NOT_AUTHORIZED'));
      }
      return resolve('IS_VALID');
    });
  }

  // ----------------------
  // Render
  // ----------------------

  render() {
    return (
      <Route
        render={() => <this.props.component {...this.state.inheritedProps} />}
      />
    );
  }
}

// ----------------------
// Redux/Router Mappings
// ----------------------

const mapStateToProps = (state) => {
  return { userContext: state.common.userContext };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserContext: (userGuid) => {
      return dispatch(commonActionCreators.fetchUserContext(userGuid));
    },
    setUserContext: (userContext) => {
      return dispatch(commonActionCreators.setUserContext(userContext));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicRoute));
