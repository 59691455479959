import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import commonActionCreators from '../../shared/store/common/action-creators';
import CustomForm from "../../shared/components/form/CustomForm";
import {LoginFormCofig, ResetPasswordForm, MfaForm} from "../../shared/components/form/LoginForm";
import classNames from 'classnames';

import './Login.scss';
import {Auth} from "../../shared/services/aws";
import {updateSharedStorage} from "../../shared/services/shared-storage";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: null,
      isNewPasswordRequired: false,
      user: null,
      isMfaRequired: false,
      mfaType: null,
      properties: {
        confirmNewPassword: '',
        email: '',
        password: '',
        newPassword: '',
        cede: '',
      },
      isLoading: false
    };
  }

  // ----------------------
  // Handlers
  // ----------------------

  catchAuthenticationError = (authenticationError) => {
    const getFormErrors = () => {
      if (authenticationError.message) {
        return [{key: '0', message: authenticationError.message}];
      }
      return [{key: '0', message: 'Unknown authentication error.'}];
    };
    this.setState({
      formErrors: getFormErrors(),
      isLoading: false
    });

    return {status: 'AUTHENTICATION_ERROR'};
  };

  catchNewPasswordError = (newPasswordError) => {
    const getFormErrors = () => {
      if (newPasswordError.message) {
        return [{key: '0', message: newPasswordError.message}];
      }
      return [{key: '0', message: 'Unknown error setting new password.'}];
    };
    this.setState({
      formErrors: getFormErrors(),
      isLoading: false
    });
    return {status: 'NEW_PASSWORD_ERROR'};
  };

  doAuthenticate(form) {
    this.setState({
      formErrors: null,
      isLoading: true,
      properties: {
        ...this.state.properties,
        email: form.email,
        password: form.password
      },
    });

    return Auth.signIn(
      form.email.trim().toLowerCase(),
      form.password.trim()
    )
      .then(result => {
        console.log(result)
        if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.setState({
            isNewPasswordRequired: true,
            isLoading: false
          });
        } else if (result.challengeName === 'SMS_MFA' ||
          result.challengeName === 'SOFTWARE_TOKEN_MFA') {
          console.log("MFA")
          this.setState({
            user: result,
            isMfaRequired: true,
            isLoading: false
          });
        } else {
          this.doAuthorize(result.signInUserSession.accessToken.jwtToken);
        }
      })
      .then(() => updateSharedStorage())
      .catch(this.catchAuthenticationError);

    // const result = await AuthService.authenticate(
    //   this.state.properties.email.trim(),
    //   this.state.properties.password.trim(),
    // )
    //   .then(authenticationResult => authenticationResult)
    //   .catch(this.catchAuthenticationError);


  }

  async doAuthenticateAndChangePassword(form) {
    this.setState({
      isLoading: true
    });

    Auth.signIn(
      this.state.properties.email.trim().toLowerCase(),
      this.state.properties.password.trim(),
    )
      .then((user) => {
        return Auth.completeNewPassword(
          user,
          form.new_password,
          null,
        );
      })
      .then(data =>
        this.doAuthorize(data.signInUserSession.accessToken.jwtToken))
      .catch(this.catchNewPasswordError);

  }

  async doConfirmMfaCode(form) {
    this.setState({
      isLoading: true
    });

    Auth.confirmSignIn(
      this.state.user, // Return object from Auth.signIn()
      form.code, // Confirmation code
      this.state.mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    ).then(user => {
      console.log(user)
        //return this.doAuthorize(user.signInUserSession.accessToken.jwtToken)
      }
    )
      .catch(this.catchNewPasswordError);

  }

  doAuthorize(token) {
    this.props
      .fetchUserContext(token)
      .then((userContext) => {
        const deptGuid = userContext.departmentGuid;
        Auth.currentAuthenticatedUser().then((user) => {
          let promise;
          if(deptGuid) {
            promise = Auth.updateUserAttributes(user, {
              'custom:deptGuid': deptGuid,
              'custom:app': 'DW',
            })
          } else {
            promise = Promise.resolve(user)
          }

          promise
            .then(() => {
              this.setState({
                isLoading: false
              });

              // important - don't redirect until the promise is resolved
              const redirect =
                userContext.scope === 'admin'
                  ? '/admin/dashboard'
                  : '/dashboard';
              window.location.href = redirect;
            })
            .catch(err => console.log(err));
        });
      })
      .catch((userContextError) => {
        // TODO Show polite error alert
        console.error(`[Login] handleSubmit() userContextError: ${JSON.stringify(userContextError)}`);
      });
  }

  // Main authentication and authorization flow, which has three key elements:
  //   1. Authenticate (against AWS Cognito)
  //   2. Authorize (against serverside API)
  //   3. On successful authentication and authorization, the User is forwarded to the dashboard
  //        for their authorized role

  render() {
    return (
      <div className="login login-page">
        <div className="login-form column">
          {this.state.formErrors && (
            <div className="alert alert-danger">
              <ul className="mb-0 pl-3">
                {this.state.formErrors.map((error) => {
                  return <li key={error.key}>{error.message}</li>;
                })}
              </ul>
            </div>
          )}

          <div className={classNames({
                    firstLoginAtempt: this.state.isNewPasswordRequired
                  })}>

            <CustomForm
              config={LoginFormCofig}
              saveLabel="Login"
              onSubmit={(form) => this.doAuthenticate(form)}
              isLoading={this.state.isLoading}
            />
          </div>

          {this.state.isNewPasswordRequired && (
            <CustomForm
              config={ResetPasswordForm}
              saveLabel="Change Password"
              onSubmit={(form) => this.doAuthenticateAndChangePassword(form)}
              isLoading={this.state.isLoading}
            />
          )}

          {this.state.isMfaRequired && (
            <CustomForm
              config={MfaForm}
              saveLabel="Muti-factor Authentication"
              onSubmit={(form) => this.doConfirmMfaCode(form)}
              isLoading={this.state.isLoading}
            />
          )}

          {!this.state.isNewPasswordRequired && (
            <Fragment>
              <div className="login-actions">
                <a href="https://profile.departmentware.com/forgot-password">Forgot Password?</a>
              </div>
            </Fragment>
          )}

        </div>
      </div>
    );
  }
}

// ----------------------
// Redux mapping
// ----------------------
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserContext: (userGuid) => {
      return dispatch(commonActionCreators.fetchUserContext(userGuid));
    },
    setUserContext: (userContext) => {
      return dispatch(commonActionCreators.setUserContext(userContext));
    },
  };
};
export default connect(null, mapDispatchToProps)(Login);
