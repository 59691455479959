import ApiService, {ApiGetCommon, ApiPostAdmin} from '../../services/api-service';
import commonCommitTypes from './commit-types';
import AxiosError from '../axios-error';
import notificationsActionsCreator from '../../../subscriber-app/store/notifications/action-creators';

const commonActions = {
  clearApiError() {
    return { type: commonCommitTypes.CLEAR_API_ERROR };
  },
  fetchUserContext(token) {
    return async (dispatch) => {
      dispatch(this.setIsLoading('fetchUserContext'));
      const data = await ApiService.post('/api/v1/validate', { jwt: token })
        .then(response => response.data)
        .catch(error => ({ error }));
      dispatch(this.setIsLoading('fetchUserContext'));
      if (data.error) {
        dispatch(commonActions.handleApiError(data.error));
        return Promise.resolve(null);
      }
      return Promise.resolve(data);
    };
  },
  fetchFormTypes() {
    return (dispatch, getState) => {
      if(getState().common.formTypes.length) {
        return Promise.resolve()
      } else {
        return ApiGetCommon('/api/v1/form-types')
          .then(({form_types}) => dispatch({
            type: commonCommitTypes.FETCH_FORM_TYPES,
            form_types
          }))
      }
    }
  },
  createFormType(type) {
    return (dispatch, getState) => {
      return ApiPostAdmin('/form-type', {'form_type': {type}})
    }
  },
  handleApiError(error) {
    return (dispatch) => {
      dispatch(notificationsActionsCreator.addNotification({
        type: 'error',
        content: error.statusText,
      }));
      dispatch({
        apiError: AxiosError.format(error),
        type: commonCommitTypes.PUSH_API_ERROR,
      });
    };
  },
  resetCommonState() {
    return {
      type: commonCommitTypes.RESET_COMMON_STATE,
    };
  },
  setIsLoading(isLoading) {
    return {
      isLoading,
      type: commonCommitTypes.SET_COMMON_IS_LOADING,
    };
  },
  setRedirectingTo(redirectingTo) {
    return {
      redirectingTo,
      type: commonCommitTypes.SET_REDIRECTING_TO,
    };
  },
  setUserContext(userContext) {
    return {
      userContext,
      type: commonCommitTypes.SET_USER_CONTEXT,
    };
  },
  switchRole(userId, roleGuid) {
    return async (dispatch) => {
      dispatch(this.setIsLoading('switchRole'));

      const data = await ApiService.post(`/api/v1/user-contexts/${userId}/role`, {
        roleGuid,
      })
        .then(response => response.data)
        .catch(error => ({ error }));

      dispatch(this.setIsLoading('switchRole'));
      if (data.error) {
        dispatch(commonActions.handleApiError(data.error));
        return Promise.reject(null);
      }
      return Promise.resolve(data);
    };
  },
};

export default commonActions;
