import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import SeparatorHeading from '../../shared/ui/SeparatorHeading';

const ProductOverview = () => (
  <Fragment>
    <div className="product-overview">
      <SeparatorHeading heading="Product Overview" stroked />
      <Container>
        <div className="overview-section">
          <div className="preview-container">
            <div className="preview-screen">
              <img src="/images/product-overview-1.png" alt="" />
            </div>
          </div>
          <div className="preview-info">
            <h4 className="preview-headline"> Accessibility </h4>
            <h6>
              <i className="fa fa-certificate icon-feature" />
              Accessible anywhere with an internet connection
            </h6>

            <h6>
              <i className="fa fa-certificate icon-feature" />
              Operates on desktops, laptops, tablets and smart phones.
            </h6>

            <h6>
              <i className="fa fa-certificate icon-feature" />
              Easy to operate and affordable.
            </h6>

            <h6>
              <i className="fa fa-certificate icon-feature" />
              Automatic upgrades at no additional cost.
            </h6>

            <Link className="button primary" to="/request-demo">
              <span>Request Demo</span>&nbsp;
              <i className="fa fa-chevron-right" />
            </Link>
          </div>
        </div>
        <div className="overview-section ">
          <div className="preview-info">
            <h4 className="preview-headline"> Features </h4>
            <h6>
              <i className="fa fa-certificate icon-feature" />
              San Jose Model with flexibility to allow individual department
              modification for integration into your existing FTO program
            </h6>

            <h6>
              <i className="fa fa-certificate icon-feature" />
              Ability to attach supporting documentation including dash and body
              cam video.
            </h6>

            <h6>
              <i className="fa fa-certificate icon-feature" />
              Allows FTO, Supervisor and Command Staff personnel to instantly
              view and track all officer in training progress in the system.
            </h6>

            <h6>
              <i className="fa fa-certificate icon-feature" />
              Provides the Command Staff and City’s Legal Department instant
              access to accurate information and supporting documentation should
              the need arise thus cutting personnel costs and mitigating risk to
              the city
            </h6>
          </div>

          <div className="preview-container">
            {/* <img src="/images/screen1.png"/> */}
            <div className="preview-screen security">
              <img src="/images/product-overview-2.png" alt="" />
            </div>
          </div>
        </div>
        <div className="overview-section">
          <div className="preview-container">
            <div className="preview-screen chart">
              <img src="/images/product-overview-3.png" alt="" />
            </div>
          </div>
          <div className="preview-info">
            <h4 className="preview-headline"> Security </h4>
            <h6>
              <i className="fa fa-certificate icon-feature" />
              Data is fully encrypted, end-to-end. Your information is protected
              and controls are in place to prevent unauthorized access.
            </h6>

            <h6>
              <i className="fa fa-certificate icon-feature" />
              Security standards meet or exceed industry best practices
            </h6>

          </div>
        </div>
      </Container>
    </div>
  </Fragment>
);

export default ProductOverview;
