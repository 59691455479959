import { Formik } from 'formik';
import React from 'react';
import { Form, FormFeedback, FormGroup, Label, Input } from 'reactstrap';

// eslint-disable-next-line arrow-body-style
const RequestDemoForm = (props) => {
  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={props.validationSchema}
      onSubmit={props.onSubmit}
      render={({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        touched,
        values,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="title">Title *</Label>
            <Input
              className={`${touched.title && errors.title && 'is-invalid'}`}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              name="title"
              id="title"
              placeholder="Title"
              value={values.title}
            />
            <FormFeedback>{errors.title}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="firstName">First Name *</Label>
            <Input
              className={`${touched.firstName &&
                errors.firstName &&
                'is-invalid'}`}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              name="firstName"
              id="firstName"
              placeholder="First Name"
              value={values.firstName}
            />
            <FormFeedback>{errors.firstName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Last Name *</Label>
            <Input
              className={`${touched.lastName &&
                errors.lastName &&
                'is-invalid'}`}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Last Name"
              value={values.lastName}
            />
            <FormFeedback>{errors.lastName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="email">Email *</Label>
            <Input
              className={`${touched.email && errors.email && 'is-invalid'}`}
              onChange={handleChange}
              onBlur={handleBlur}
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={values.email}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="phone">Phone *</Label>
            <Input
              className={`${touched.phone && errors.phone && 'is-invalid'}`}
              onChange={handleChange}
              onBlur={handleBlur}
              type="email"
              name="phone"
              id="phone"
              placeholder="Phone"
              value={values.phone}
            />
            <FormFeedback>{errors.phone}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="contactPreference">Contact Preference</Label>
            <Input
              className="custom-select"
              type="select"
              name="contactPreference"
              id="contactPreference"
            >
              <option defaultValue>Phone</option>
              <option>Email</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="departmentName">Department Name *</Label>
            <Input
              className={`${touched.departmentName &&
                errors.departmentName &&
                'is-invalid'}`}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              name="departmentName"
              id="departmentName"
              placeholder="Department Name"
              value={values.departmentName}
            />
            <FormFeedback>{errors.departmentName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="address1">Address *</Label>
            <Input
              className={`${touched.address1 &&
                errors.address1 &&
                'is-invalid'}`}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              name="address1"
              id="address1"
              placeholder="Address"
              value={values.address1}
            />
            <FormFeedback>{errors.departmentName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="address2">Address</Label>
            <Input
              type="text"
              name="address2"
              id="address2"
              placeholder="Address"
            />
          </FormGroup>
          <FormGroup>
            <Label for="city">City *</Label>
            <Input
              className={`${touched.city && errors.city && 'is-invalid'}`}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              name="city"
              id="city"
              placeholder="City"
              value={values.city}
            />
            <FormFeedback>{errors.city}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="state">State*</Label>
            <Input
              className={`custom-select ${touched.state &&
                errors.state &&
                'is-invalid'}`}
              onChange={handleChange}
              onBlur={handleBlur}
              type="select"
              name="state"
              id="state"
              value={values.state}
            >
              <option defaultValue value="" />
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </Input>
            <FormFeedback>{errors.state}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="zip">Zip Code *</Label>
            <Input
              className={`${touched.zip && errors.zip && 'is-invalid'}`}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              name="zip"
              id="zip"
              placeholder="Zip Code"
              value={values.zip}
            />
            <FormFeedback>{errors.zip}</FormFeedback>
          </FormGroup>
          <div style={{ textAlign: 'right' }}>
            <button className="button primary" disabled={!isValid}>
              Submit
            </button>
          </div>
        </Form>
      )}
    />
  );
};

export default RequestDemoForm;
