import React from 'react';
import SeparatorHeading from '../../shared/ui/SeparatorHeading';

const OurTeam = () => (
  <div className="our-team">
    <SeparatorHeading heading="Executive team" stroked />
    <div className="team-group">
      <div className="our-team-card">
        <div className="team-image">
          <div className="hexagon-in1">
            <div
              className="hexagon-in2"
              style={{ backgroundImage: 'url(/images/joe.jpg)' }}
            />
          </div>
        </div>
        <div className="person-linkedin">
          <i className="fa fa-linkedin" />
        </div>
        <div className="person-email">
          <i className="fa fa-envelope" />
        </div>
        <div className="team-text">
          <div className="person-name">Joe Bixler</div>
          <div className="person-title">Founder & CEO</div>
          Prior to launching Departmentware, Joe held various senior level sales
          and management positions in the newspaper, broadcast and public safety
          industries. His passion for working with first responders led to the
          birth of Departmentware. Joe is recognized for building and training
          sales teams while identifying and implementing new revenue streams.
          Under Joe’s leadership, his teams experienced double digit growth in
          both customer acquisition and revenue. He is active in several groups
          including Public Safety Professionals and participated as a guest
          speaker at ABM Security’s annual conference speaking on industry
          changes in “educational requirements for licensed security personnel.”
          Joe holds a BS Degree in Accounting from Bentley University (formerly
          Bentley College). His leisure time is spent with his wife, grandson
          and Airedale terriers. He also enjoys baseball and an occasional round
          of golf.
        </div>
      </div>
      <div className="our-team-card">
        <div className="team-image">
          <div className="hexagon-in1">
            <div
              className="hexagon-in2"
              style={{ backgroundImage: 'url(/images/gary.jpg)' }}
            />
          </div>
        </div>
        <div className="person-linkedin">
          <i className="fa fa-linkedin" />
        </div>
        <div className="person-email">
          <i className="fa fa-envelope" />
        </div>
        <div className="team-text">
          <div className="person-name">Gary L. Ramey</div>
          <div className="person-title">Founder & Chief Strategy Officer</div>
          Having served in various executive roles during his career Gary has
          also served as founder and CEO of Smartworkforce Solutions LLC based
          in Salt Lake City Utah prior to the launch of DepartmentWare. His
          extensive experience driving businesses to profitability and
          optimizing strategic execution will serve our company well. Noted for
          tenacity, flexibility, creativity and teamwork, he fosters work
          environments in which all are encouraged to contribute to
          organizational success. His sales strategy employs a very simple
          approach. Sales is simply the result of offering a quality solution
          while building and maintaining trusted relationships. Gary has
          participated as a guest speaker in various venues including Microsofts
          GPAC conference speaking on “the value of certification in the
          workforce”. Additional career highlights include being awarded the
          “2006 Digital Literacy Award” in support of bridging the global
          digital literacy gap.
        </div>
      </div>
    </div>

    <SeparatorHeading heading="Technical team" stroked />
    <div className="team-group">
      <div className="our-team-card">
        <div className="team-image">
          <div className="hexagon-in1">
            <div
              className="hexagon-in2"
              style={{ backgroundImage: 'url(/images/robert.jpg)' }}
            />
          </div>
        </div>
        <a
          className="person-linkedin"
          href="https://www.linkedin.com/in/bponghneng/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-linkedin" />
        </a>
        <div className="person-email">
          <i className="fa fa-envelope" />
        </div>
        <div className="team-text">
          <div className="person-name">Robert Pratt</div>
          <div className="person-title">Full-stack digital nomad</div>
          Trained as a journalist, Robert became fascinated with the World Wide
          Web even before browsers displayed images. While working as an arts
          editor for a leading publication in Santa Cruz, Calif., his first
          major web project created and maintained the publications online
          edition. Since then, he has worked with data-driven applications: from
          Java to PHP to Node.js and now to Elixir. A lifelong professional
          musician, his musical interests often coincide with his tech
          interests, and he has years of experience working with global
          companies to produce professional and consumer digital sampling and
          synthesis products. In recent years, he has traveled the world
          extensively, and he currently resides in Bangkok, Thailand.
        </div>
      </div>
      <div className="our-team-card">
        <div className="team-image">
          <div className="hexagon-in1">
            <div
              className="hexagon-in2"
              style={{ backgroundImage: 'url(/images/renee.jpg)' }}
            />
          </div>
        </div>
        <a
          className="person-linkedin"
          href="https://www.linkedin.com/in/reneerojas/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-linkedin" />
        </a>

        <div className="person-email">
          <i className="fa fa-envelope" />
        </div>
        <div className="team-text">
          <div className="person-name">Renee Rojas</div>
          <div className="person-title">Data & Infrastructure Engineer</div>
          Passionate about technology, with more than 20 years coding
          experience, Renee has built software and infrastructure for a variety
          of industries including copyright, telecom, textile and health care.
          Significant experience with PHP, Java, Scala and now Elixir, Renee
          loves learning and actively seeks out challenging projects.
        </div>
      </div>
      <div className="our-team-card">
        <div className="team-image">
          <div className="hexagon-in1">
            <div
              className="hexagon-in2"
              style={{ backgroundImage: 'url(/images/kliment.jpg)' }}
            />
          </div>
        </div>
        <div className="person-linkedin">
          <i className="fa fa-linkedin" />
        </div>
        <div className="person-email">
          <i className="fa fa-envelope" />
        </div>
        <div className="team-text">
          <div className="person-name">Kliment Lambevski</div>
          <div className="person-title">Senior Front-end Engineer</div>
          Experienced software developer with over five years of professional
          experience. After graduating Saints Cyril and Methodius University of
          Skopje, I started as a frontend developer. Since then, I have led
          software teams building a large medical application and helped launch
          a number of US-based startups.
          <br />
          <br />
          As a freelance consultant, I am always interested in new projects.
        </div>
      </div>
    </div>
  </div>
);

export default OurTeam;
