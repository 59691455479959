import React from 'react';
import './Checkbox.scss';

const Checkbox = ({checked, onChange, label, name, disabled}) => {
  return (
    <label className='checkbox'>
      <input type='checkbox' name={name} checked={checked} onChange={(e) => onChange(e)} disabled={disabled}/>
      <div className='checkbox-label'>
        <div className='is-checked'>
          <i className='fa fa-check'/>
        </div>
      </div>
      <div className="label">
        {label}
      </div>
    </label>
  )
};

export default Checkbox;