import React, { Component } from 'react';
import { Container } from 'reactstrap';
import classNames from 'classnames';
import SeparatorHeading from '../../shared/ui/SeparatorHeading';

class Faq extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: 0, cards: [1, 2, 3, 4, 5, 6] };
    this.getQuestion = this.getQuestion.bind(this);
    this.getAnswer = this.getAnswer.bind(this);
  }

  getQuestion(index) {
    switch (index) {
      case 1:
        return 'Do we need to purchase any separate equipment to run this software?';
      case 2:
        return 'Our department uses desktops and laptops. Will the software run on both devices?';
      case 3:
        return 'Can we customize the daily observation report for issues specific to our department?';
      case 4:
        return 'Can I compare officer in training performance?';
      case 5:
        return 'Can we still print reports?';
      case 6:
        return 'Can officers in training evaluate their FTO?';
      default:
        return '';
    }
  }

  getAnswer(index) {
    switch (index) {
      case 1:
        return 'No. All you need is internet access.';
      case 2:
        return 'Yes. The software is compatible on desktops, laptops, tablets and smart phones.';
      case 3:
        return "Yes. The DOR is based on the San Jose model with the added flexibility to add/delete/modify observations to mirror your department's existing FTO program";
      case 4:
        return 'Yes. FTO, Supervisor and Command Staff dashboards provide a snapshot of the progress and performance of each officer in training.';
      case 5:
        return 'Yes. You can print any of the reports subject to your administrative access.';
      case 6:
        return 'Yes. The FTO Critique form is built into the final phase of training and requires the officer in training to submit their evaluation prior to completing the field training program.';
      default:
        return '';
    }
  }

  toggle(index) {
    this.setState({
      collapse: index === this.state.collapse ? -1 : index,
    });
  }

  render() {
    const { cards, collapse } = this.state;
    return (
      <div className="faq">
        <SeparatorHeading heading="Frequently Asked Questions" stroked />
        <Container>
          {cards.map(index => (
            <div
              role="Tabpanel"
              className="faq-item"
              key={index}
              onClick={() => this.toggle(index)}
            >
              <div className="faq-question-container">
                <div
                  className={classNames('faq-item-icon', {
                    opened: collapse === index,
                  })}
                >
                  {collapse === index ? (
                    <i className="fa fa-check-circle" />
                  ) : (
                    <i className="fa fa-question-circle-o" />
                  )}
                </div>
                <div className="faq-question">{this.getQuestion(index)}</div>
                <div className="faq-item-arrow">
                  {collapse === index ? (
                    <i className="fa fa-chevron-up" />
                  ) : (
                    <i className="fa fa-chevron-down" />
                  )}
                </div>
              </div>
              <div
                className={classNames('faq-answer', {
                  opened: collapse === index,
                })}
              >
                {this.getAnswer(index)}
              </div>
            </div>
          ))}
          {/* <Card style={{ marginBottom: '1rem' }} key={index}> */}
          {/* <CardHeader onClick={this.toggle} data-event={index}> */}
          {/* {this.getQuestion(index)} */}
          {/* </CardHeader> */}
          {/* <Collapse isOpen={collapse === index}> */}
          {/* <CardBody>{this.getAnswer(index)}</CardBody> */}
          {/* </Collapse> */}
          {/* </Card> */}
        </Container>
      </div>
    );
  }
}

export default Faq;
