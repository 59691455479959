const user = (userContextJson) => {
  return {
    ...userContextJson,
    hasScope: (scopes) => {
      return !userContextJson ? false : scopes.includes(userContextJson.scope);
    },
    isAuthenticated: !!userContextJson,
  };
};

export default user;
