import React, { Component } from 'react';
import { connect } from 'react-redux';
import Yup from 'yup';
import {Card, Container} from 'reactstrap';
import RequestDemoForm from '../components/RequestDemoForm';
import publicActionCreators from '../store/public/action-creators';
import SeparatorHeading from '../../shared/ui/SeparatorHeading';
import {withRouter} from "react-router-dom";
import './RequestDemo.scss';
import LoadingOverlay from "../../shared/components/LoadingOverlay";

class RequestDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestSent: false,
      isLoading: false
    };
  }

  submitDemo(formData) {
    this.setState({
      isLoading: true
    });

    this.props.createDemoRequest(formData)
      .then(() => this.setState({
        requestSent: true,
        isLoading: false
      }))
      .catch(() => this.setState({
        isLoading: false
      }))
  }

  initialValues = {
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    contactPreference: '',
    departmentName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  };

  validationSchema = () =>
    Yup.object().shape({
      title: Yup.string().required('Title is required'),
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string()
        .required('Email is required')
        .email('Invalid email format'),
      phone: Yup.string()
        .required('Phone is required')
        .matches(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/, {
          message: 'Invalid phone format',
          excludeEmptyString: true,
        }),
      departmentName: Yup.string().required('Department name is required'),
      address1: Yup.string().required('Department name is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      zip: Yup.string()
        .required('Zip code is required')
        .matches(/^\d{5}(-\d{4})?$/, {
          message: 'Invalid zip code format',
          excludeEmptyString: true,
        }),
    });

  render() {
    return (
      <div className="request-demo">
        <LoadingOverlay isLoading={this.state.isLoading} fullscreen>
        <SeparatorHeading heading="Request Demo" stroked />
        {!this.state.requestSent &&
          <Container>
            <RequestDemoForm
              initialValues={this.initialValues}
              onSubmit={this.submitDemo.bind(this)}
              validationSchema={this.validationSchema}
            />
            <br/>
            <br/>
          </Container>
        }

        {this.state.requestSent &&
          <Container>
            <Card>
              <div className="request-sent-message">
                Your request has been sent. Thank you for your interest!
              </div>
            </Card>
          </Container>
        }

        </LoadingOverlay>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createDemoRequest: (formData) => {
      return dispatch(publicActionCreators.createDemoRequest(formData))
    }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(RequestDemo));
