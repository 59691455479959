import React, {Fragment, Component, cloneElement, Children} from "react";
import classNames from 'classnames';

function isElementInViewport(el) {
  let rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    // rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    // rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  ); //compute only vertical visibility
}

class AnimateInViewport extends Component {
  constructor(props) {
    super(props);
    this.modifyChildren = this.modifyChildren.bind(this);
    this.checkIfElementInViewport = this.checkIfElementInViewport.bind(this);

    this.state = {
      inViewport: false
    };

    this.childReference = null;
  }

  modifyChildren(child) {
    const className = classNames(
      child.props.className,
      this.state.inViewport? 'in-viewport': ''
    );

    const props = {
      className,
      ref: (n) => this.childReference = n
    };
    return cloneElement(child, props);
  }

  checkIfElementInViewport() {
    if(!this.state.inViewport && isElementInViewport(this.childReference)) {
      setTimeout(() => {
        this.setState({inViewport: true})
      }, this.props.delay || 0);
    }
  }

  componentDidMount() {
    if(window.IntersectionObserver && false) { //disable IntersectionObserver for now
      let options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      };

      let observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.state.inViewport) {
            setTimeout(() => {
              this.setState({inViewport: true})
            }, this.props.delay || 0);
          }
        })
      }, options);

      observer.observe(this.childReference);

    } else {
      if (window.addEventListener) {
        window.addEventListener('DOMContentLoaded', this.checkIfElementInViewport, false);
        window.addEventListener('load', this.checkIfElementInViewport, false);
        window.addEventListener('scroll', this.checkIfElementInViewport, false);
        window.addEventListener('resize', this.checkIfElementInViewport, false);
      } else {
        setTimeout(() => {
          this.setState({inViewport: true})
        }, this.props.delay || 0);
      }
    }
  }

  componentWillUnmount() {
    if (window.removeEventListener) {
      window.removeEventListener('DOMContentLoaded', this.checkIfElementInViewport, false);
      window.removeEventListener('load', this.checkIfElementInViewport, false);
      window.removeEventListener('scroll', this.checkIfElementInViewport, false);
      window.removeEventListener('resize', this.checkIfElementInViewport, false);
    }
  }

  render() {
    return (
      <Fragment>
        {Children.map(this.props.children, child => this.modifyChildren(child))}
      </Fragment>
    )
  }
}

export default AnimateInViewport;