import React, {Component} from 'react';
import {Auth} from "../../shared/services/aws";
import CustomForm from "../../shared/components/form/CustomForm";
import {SendResetEmailCofig} from "../../shared/components/form/LoginForm";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: null,
      formInfo: null,
      isVerificationCodeSent: false,
      isLoading: false
    };
  }

  catchForgotPasswordError = (forgotPasswordError) => {
    const getFormErrors = () => {
      switch (forgotPasswordError.code) {
        case 'CodeMismatchException':
        case 'InvalidPasswordException':
        case 'InvalidParameterException':
        case 'LimitExceededException':
        case 'UserNotFoundException':
          return [{key: '0', message: forgotPasswordError.message}];
        default:
          return [{key: '0', message: 'Unknown password reset error.'}];
      }
    };
    this.setState({
      formErrors: getFormErrors(),
      isLoading: false
    });
  };

  handleSubmit = (form) => {
    this.setState({
      isLoading: true
    });

    Auth.forgotPassword(form.email.toLowerCase())
      .then(() => {
        this.setState({
          formInfo: [
            {
              key: '0',
              message:
                'An email with instructions to reset your password has been sent.',
            },
          ],
          isVerificationCodeSent: true,
          isLoading: false
        });
      })
      .catch(error => this.catchForgotPasswordError(error));
  };

  render() {
    return (
      <div className="login">
        <div className="login-form column">
          {this.state.formErrors && (
            <div className="alert alert-danger">
              <ul className="mb-0 pl-3">
                {this.state.formErrors.map((error) => {
                  return <li key={error.key}>{error.message}</li>;
                })}
              </ul>
            </div>
          )}
          {this.state.formInfo && (
            <div className="alert alert-primary">
              <ul className="mb-0 pl-3">
                {this.state.formInfo.map((info) => {
                  return <li key={info.key}>{info.message}</li>;
                })}
              </ul>
            </div>
          )}
          {!this.state.isVerificationCodeSent && (
            <CustomForm
              config={SendResetEmailCofig}
              saveLabel="Send Reset Email"
              onSubmit={(form) => this.handleSubmit(form)}
              isLoading={this.state.isLoading}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
