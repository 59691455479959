import React, {Component, Fragment} from "react";
import './ColorLabels.scss'
import {connect} from "react-redux";
import classNames from "classnames";
import departmentSettingsActionsCreators from "../../../subscriber-app/store/department-settings/action-creators";

class ColorLabels extends Component {
  constructor(props) {
    super(props);

    console.log(this.props.settings.customLabels)
    this.state = {
      createOpened: (!this.props.settings.customLabels || this.props.settings.customLabels.length === 0),
      color: '#000000',
      label: '',
      value: this.props.value
    }
  }

  get customLabels() {
    return this.props.settings.customLabels || []
  }

  selectValue(cl) {
    console.log('select value', cl)
    this.setState({
      value: cl
    })
    this.props.onChange && this.props.onChange(cl)
  }

  createLabel() {
    if(this.state.color && this.state.label) {
      this.props.updateDepartmentSettings({
        customLabels: [...this.customLabels, {
          label: this.state.label,
          color: this.state.color
        }]
      })
        .then(() => {
          this.setState({
            color: '#000000',
            label: '',
            createOpened: false
          })
        });
    }
  }

  render() {
    return (
      <div className="color-labels">
        {this.state.createOpened &&  <Fragment>
          <input value={this.state.color} onChange={e => this.setState({color: e.target.value})} type="color"/>
          <input value={this.state.label} onInput={e => this.setState({label: e.target.value})} type="text" placeholder="Label"/>
          <div><button onClick={() => this.createLabel()} type="button" className="button primary small"><i className="fa fa-plus"></i>Create new</button> </div>
          <div><button onClick={() => this.setState({createOpened: false})} type="button" className="button secondary small"><i className="fa fa-pencil"></i>Choose existing</button> </div>
        </Fragment>}

        {!this.state.createOpened && <Fragment>
          <div className="color-label-list">
            {this.customLabels.map(cl => (
              <div className={classNames("color-label", {"selected": this.state.value && this.state.value.label === cl.label})}  onClick={() => this.selectValue(cl)}>
                <div className="color-box" style={{backgroundColor: cl.color }}></div>
                <div>{cl.label}</div>
              </div>
            ))}
            <div><button onClick={() => this.setState({createOpened: true})} type="button" className="button primary small"><i className="fa fa-plus"></i>Create new</button> </div>

          </div>
        </Fragment>}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.departmentSettings.settings,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateDepartmentSettings: (formData) => {
      return dispatch(departmentSettingsActionsCreators.updateDepartmentSettingsLabels(formData));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ColorLabels);
