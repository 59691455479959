import {validateRequired, validateEmail} from "./ValidationFunctions";

const LoginFormCofig = {
  fields: {
    email: {
      type: 'text',
      label: 'Email *',
      rules: [validateRequired('Email'), validateEmail]
    },
    password: {
      type: 'password',
      label: 'Password *',
      rules: [validateRequired('Password')]
    }
  }
};

const SendResetEmailCofig = {
    fields: {
        email: {
          type: 'text',
          label: 'Email *',
          rules: [validateRequired('Email'), validateEmail]
        }
    }
};

const ResetPasswordForm = {
    fields: {
        new_password: {
          type: 'password',
          label: 'New Password *',
          rules: [validateRequired('Password')]
        }
    }
};

const MfaForm = {
    fields: {
        code: {
          type: 'text',
          label: 'Code *',
          rules: [validateRequired('Code')]
        }
    }
};


export {
  LoginFormCofig,
  SendResetEmailCofig,
  ResetPasswordForm,
  MfaForm
}
