import React from 'react';
// import { Elements } from 'react-stripe-elements';
// import CheckoutForm from '../components/CheckoutForm';

const Pricing = () => (
  <div>
    <h2>[Joe/Gary - Need Updated Pricing]</h2>
    {/*
    <Elements>
      <CheckoutForm />
    </Elements>
    */}
  </div>
);

export default Pricing;
