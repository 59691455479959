import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <div className="footer">
    <div className="footer-section vertical-divider">
      <div className="footer-header">DEPARTMENTWARE</div>
      <img className="logo" alt="Departmentware Logo" src="/images/logo.png" />
      <div className="footer-text">Better software for public safety.</div>
      <div className="footer-divider" />
      <div className="footer-text">© {new Date().getFullYear()} Departmentware, Inc. </div>
    </div>
    <div className="footer-section vertical-divider">
      <div className="footer-header">QUICK LINKS</div>
      <div className="footer-link">
        <i className="fa fa-angle-double-right" />
        <Link to="/our-team">Our Team</Link>
      </div>
      <div className="footer-link">
        <i className="fa fa-angle-double-right" />
        <Link to="/product-overview">Product Overview</Link>
      </div>
      <div className="footer-link">
        <i className="fa fa-angle-double-right" />
        <Link to="/request-demo">Request Demo</Link>
      </div>
      <div className="footer-link">
        <i className="fa fa-angle-double-right" />
        <Link to="/FAQ">FAQ</Link>
      </div>
      <div className="footer-link">
        <i className="fa fa-angle-double-right" />
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
    </div>
    <div className="footer-section">
      <div className="footer-header">CONTACT US</div>
      <div className="footer-link">
        <i className="fa fa-phone" />
        <a href="tel:1-469-759-9489">(469) 759-9489</a>
      </div>
      <div className="footer-link">
        <i className="fa fa-envelope" />
        <a href="mailto:information@departmentware.com">information@departmentware.com</a>
      </div>
    </div>
  </div>
);

export default Footer;
