import commonActionCreators from '../../shared/store/common/action-creators';

const rootActions = {
  resetRootState() {
    return (dispatch) => {
      dispatch(commonActionCreators.resetCommonState());
    };
  },
};

export default rootActions;
