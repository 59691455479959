import publicCommitTypes from './commit-types';

const INITIAL_PUBLIC_STATE = { demoRequest: null };

function publicReducers(state = INITIAL_PUBLIC_STATE, action) {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case publicCommitTypes.CREATE_DEMO_REQUEST_SUCCESS:
      return {
        ...state,
        demoRequest: action.demoRequest,
      };
    case publicCommitTypes.RESET_PUBLIC_STATE:
      return { ...INITIAL_PUBLIC_STATE };
    default:
      return state;
  }
}

export default publicReducers;
