import React, {Component, Fragment} from 'react';
import SeparatorHeading from "../../shared/ui/SeparatorHeading";
import './CustomerSupport.scss'
import CustomerSupportForm from "./CustomerSupportForm";
import Yup from "yup";
import {ApiPost} from "../../shared/services/api-service";

class CustomerSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormSent: false
    }
  }

  initialValues = {
    name: '',
    email: '',
    message: ''
  };

  validationSchema = () =>
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string()
        .required('Email is required')
        .email('Invalid email format'),
      message: Yup.string().required('Message is required'),
    });

  submitDemo(data) {
    ApiPost('/api/v1/support-request', {
      support_request: data
    })
      .then(() => {
        this.setState({
          isFormSent: true
        })
      })
  }

  render() {
    return (
      <div className="customer-support">
        <SeparatorHeading heading="Customer Support" stroked />
        <div className="customer-support-form container">
          <div className="contact-us">
            {!this.state.isFormSent
              ? <Fragment>
                  <h3>Get in Touch</h3>
                  <p>Please fill out the quick form and we will be in touch with lightning speed.</p>
                  <CustomerSupportForm
                    initialValues={this.initialValues}
                    onSubmit={this.submitDemo.bind(this)}
                    validationSchema={this.validationSchema}
                  />
                </Fragment>
              : <Fragment>
                  <h3>Thank you for contacting us</h3>
                  <h4>We will get back too you as soon as possible</h4>
                </Fragment>
            }
          </div>
          <div className="call-us">
            <h3>Or Give Us a Call</h3>
            <h4>AT</h4>
            <h3>(469) 759-9489</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerSupport;
