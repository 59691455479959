import departmentSettingsCommitTypes from '../commit-types';
import ApiService from '../../../../shared/services/api-service';
import commonActionCreators from '../../../../shared/store/common/action-creators';
import notificationsActionsCreator from '../../notifications/action-creators';

const departmentSettingsActionCreators = {
  fetchDepartmentSettings() {
    return async (dispatch) => {
      dispatch(this.setIsLoading('fetchDepartmentSettings'));
      const data = await ApiService.get('/api/v1/department-settings')
        .then(response => response.data)
        .catch(error => ({ error }));
      dispatch(this.setIsLoading('fetchDepartmentSettings'));
      if (data.error) {
        dispatch(commonActionCreators.handleApiError(data.error));
      } else {
        dispatch(this.fetchDepartmentSettingsSuccess(data));
      }
    };
  },
  fetchDepartmentSettingsSuccess(notificationsResponse) {
    return (dispatch) => {
      dispatch(this.setDepartmentSettings(notificationsResponse.data));
    };
  },
  loadDepartmentSettings() {
    return (dispatch) => {
      dispatch(this.fetchDepartmentSettings());
    };
  },
  setDepartmentSettings(settings) {
    return {
      settings,
      type: departmentSettingsCommitTypes.SET_DEPARTMENT_SETTINGS,
    };
  },
  setIsLoading(isLoading) {
    return {
      isLoading,
      type: departmentSettingsCommitTypes.SET_DEPARTMENT_SETTINGS_IS_LOADING,
    };
  },
  updateDepartmentSettings(formData) {
    return async (dispatch) => {
      dispatch(this.setIsLoading('updateDepartmentSettings'));
      const data = await ApiService.put('/api/v1/department-settings', formData)
        .then(response => response.data)
        .catch(error => ({ error }));
      dispatch(this.setIsLoading('updateDepartmentSettings'));
      if (data.error) {
        dispatch(commonActionCreators.handleApiError(data.error));
      } else {
        dispatch(this.updateDepartmentSettingsSuccess(data));
      }
    };
  },
  updateDepartmentSettingsLabels(formData) {
    return async (dispatch) => {
      dispatch(this.setIsLoading('updateDepartmentSettings'));
      const data = await ApiService.post('/api/v1/department-settings/update_labels', formData)
        .then(response => response.data)
        .catch(error => ({ error }));
      dispatch(this.setIsLoading('updateDepartmentSettings'));
      if (data.error) {
        dispatch(commonActionCreators.handleApiError(data.error));
      } else {
        dispatch(this.updateDepartmentSettingsSuccess(data));
      }
    };
  },
  updateDepartmentSettingsSuccess(updated) {
    return (dispatch) => {
      dispatch(this.setDepartmentSettings(updated.data));
      dispatch(notificationsActionsCreator.addSavedSuccessfully('Department settings updated'))
    };
  },
};

export default departmentSettingsActionCreators;
