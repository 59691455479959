import React, {Component} from 'react';
import {connect} from 'react-redux';
import './UploadUserPhoto.scss';
import {Storage} from '../../../shared/services/aws';
import LoadingButton from '../../../shared/ui/LoadingButton';
import uuidv4 from 'uuid/v4';

class UploadUserPhoto extends Component {
  inputFile;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userPhotoUrl: (this.props.user && this.props.user.photo) ? this.getS3Link(this.props.user.photo) : null,
      userPhotoKey: null,
    };
  }

  // ----------------------
  // Methods
  // ----------------------

  getS3Link(imageUrl) {
   // Storage.get(imageUrl)
   //   .then(result => {
        this.setState({
          userPhotoUrl: imageUrl
        });
    //  })
   //   .catch((err) => {
  //      console.log(err);
    //  });
    return imageUrl
  }

  triggerUpload() {
    this.inputFile.click();
  }

  uploadFile(event) {
    const file = event.target.files[0];
    let fileExtension = file.type.split('/')[1];
    let fileName = uuidv4() + '.' + fileExtension;
    const s3Key = `${this.props.userContext.departmentGuid}/department/profiles/${fileName}`;

    this.setState({
      isLoading: true
    });

    Storage.put(s3Key, file, {contentType: file.type})
      .then((result) => {
        this.setState({
          isLoading: false,
          userPhotoKey: result.key
        });

        this.getS3Link(result.key);
        this.updatePhoto();
      })
      .catch((error) => {
        this.setState({
          isLoading: false
        });
        console.log(`[AttachmentForm] uploadFile() error: ${JSON.stringify(error)}`);
      });
  }

  updatePhoto() {
    this.props.onUpdatePhoto({
      userPhotoUrl: this.state.userPhotoKey
    });
  }

  render() {
    return (
      <div className="upload-user-photo">
        <input type="file" ref={(ref) => this.inputFile = ref} onChange={e => this.uploadFile(e)}/>

        {this.props.user &&
        <label className="photo-label">
          Photo
        </label>
        }

        <div className="img-container">
          {this.state.userPhotoUrl &&
          <img className="image" src={`/api/v1/user-image/${this.props.user.guid}`}/>
          }
        </div>

        <div className="upload-btn">
          <LoadingButton
            primary
            icon="fa fa-upload"
            text="Upload Photo"
            type="button"
            isLoading={this.state.isLoading}
            onClick={() => this.triggerUpload()}
          />
        </div>
      </div>
    );
  }
}

// ----------------------
// Redux Mappings
// ----------------------

const mapStateToProps = (state) => {
  return {
    userContext: state.common.userContext,
  };
};

export default connect(mapStateToProps)(UploadUserPhoto);
