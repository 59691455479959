import React, {Component, Fragment} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {Auth} from '../../shared/services/aws';
import rootActionCreators from '../store/action-creators';
import BrandHeader from '../../shared/ui/BrandHeader';
import {clearSharedStorage} from "../../shared/services/shared-storage";

class PublicNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = { isMenuCollapsed: true };
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  // ----------------------
  // Lifecycle
  // ----------------------

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState({ isMenuCollapsed: true });
    }
  }

  // ----------------------
  // Methods
  // ----------------------

  goToDashboard = () => {
    if(this.props.userContext.hasScope('admin')) {
      window.location.href = '/admin/dashboard';
    } else {
      window.location.href = '/dashboard';
    }
  };

  handleLogout = () => {
    Auth.signOut()
    this.props.resetUserSession();
    clearSharedStorage()
      .then(() => {
        this.props.history.push('/');
      })
  };

  toggleNavbar() {
    this.setState({ isMenuCollapsed: !this.state.isMenuCollapsed });
  }

  // ----------------------
  // Render
  // ----------------------

  render() {
    return (
      <div
        className={classNames('public-nav-bar', {
          opened: !this.state.isMenuCollapsed,
        })}
      >
        <BrandHeader />
        <div className="navbar-items">
          <NavLink className="navbar-item" to="/">
            Home
          </NavLink>
          <NavLink
            className="navbar-item"
            to="/our-team"
            activeClassName="active-page"
          >
            Our Team
          </NavLink>
          {/* <NavLink className="navbar-item" to="/FAQ"> */}
          {/* FAQ */}
          {/* </NavLink> */}
          <NavLink
            className="navbar-item"
            to="/product-overview"
            activeClassName="active-page"
          >
            Product Overview
          </NavLink>
          {/* <NavLink className="navbar-item" to="/pricing"> */}
          {/* Pricing */}
          {/* </NavLink> */}
          <NavLink
            className="navbar-item"
            to="/request-demo"
            activeClassName="active-page"
          >
            Request Demo
          </NavLink>
          {/* <NavLink className="navbar-item" to="/customer-support"> */}
          {/* Customer Support */}
          {/* </NavLink> */}
          <NavLink
            className="navbar-item"
            to="/customer-support"
            activeClassName="active-page"
          >
            Customer Support
          </NavLink>
          {/* <NavLink className="navbar-item" to="/privacy-policy"> */}
          {/* Privacy Policy */}
          {/* </NavLink> */}
          {this.props.isAuthenticated ? (
            <div className="button" onClick={this.handleLogout}>
              <i className="fa fa-sign-out" />
              <span>Logout</span>
            </div>
          ) : (
            <Fragment>
              <NavLink className="button primary" to="/login">
                <i className="fa fa-lock"/>
                <span>Login</span>
              </NavLink>
              <a className="button primary" href="https://qm.departmentware.com">
                <i className="fa fa-lock"/>
                <span>Login to QM</span>
              </a>
            </Fragment>
          )}
          {this.props.isAuthenticated && (
            <div className="button primary" onClick={this.goToDashboard}>
              <i className="fa fa-briefcase" />
              <span>Dashboard</span>
            </div>
          )}
        </div>
        <div className="navigation">
          {this.state.isMenuCollapsed ? (
            <i className="fa fa-bars" onClick={this.toggleNavbar} />
          ) : (
            <i className="fa fa-close" onClick={this.toggleNavbar} />
          )}
        </div>
        <div className="navbar-background" onClick={this.toggleNavbar} />
      </div>
    );
  }
}

// ----------------------
// Redux/Router Mappings
// ----------------------

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.common.userContext.isAuthenticated,
    userContext: state.common.userContext,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // eslint-disable-next-line arrow-body-style
    resetUserSession: () => {
      return dispatch(rootActionCreators.resetRootState());
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicNavBar));
