import React from 'react';
import {Container} from 'reactstrap';
import SeparatorHeading from "../../shared/ui/SeparatorHeading";

const PrivacyPolicy = () => (
    <div className="privacy-policy">
      <SeparatorHeading heading="Privacy Policy" stroked/>
      <Container>
        <h2>Departmentware, Inc. Privacy Policy</h2>
        <i>Last Updated: January 1, 2018</i>
        <br/>
        <br/>
        <p>
          Your privacy is important to us. In the course of using our web site, you may provide us with
          personally identifiable information. This refers to information about you that can be used to
          contact or identify you, information that you input on our site and information on your use of
          and activities at our web site that may be connected with your (“Personal Information”). This
          privacy policy applies to all of the products, services and web sites offered by
          Departmentware, Inc. and tells you how we collect, use, disclose and protect your Personal
          Information.
        </p>
        <h4>Information We Collect</h4>
        <p>
          We collect Personal Information from you when you provide it to us in forms and documents. For
          example, we may collect your name, phone number, credit card or other billing information,
          email address, business postal addresses and information regarding transactions you conduct
          using our web site.
        </p>
        <h4>How We Use the Information We Collect</h4>
        <p>
          We use your Personal Information for the following purposes: to deliver the services you
          request, to improve our web site, services, features and content, to provide or offer software
          updates and product announcements, to monitor and analyze use of our web site and our
          services, to administer our web site, and to verify that visitors to our web site meet the
          criteria required to process their requests.
        </p>
        <h4>Protection of Your Personal Information</h4>
        <p>
          We take reasonable steps to maintain the safety of your Personal Information. For example,
          sensitive Personal Information is transmitted via Secure Socket Layer (SSL) technology and
          access to your account information requires a username and password. You must keep your
          password confidential and you should always logout and close your browser when you finish your
          session.
        </p>
        <h4>Disclosure to Third Parties</h4>
        <p>
          We do not sell, transfer, or otherwise share your Personal Information to third parties or any
          data that you store using our services to any third party. This does not include trusted third
          parties we partner with to assist us in operating our web site, conducting and improving our
          business, or servicing you, in which case we may provide access to your Personal Information
          only for purposes of performing these tasks. Additionally, we may sell, transfer or otherwise
          share some or all of our assets, including your Personal Information, in connection with a
          corporate transaction such as a merger, acquisition, reorganization or sale of assets. In any
          such event, you will receive nostice if your data is transferred and becomes subject to a
          substantially different privacy policy. We may also release your Personal Information when we
          believe release is appropriate to comply with the law, enforce our site policies, or protect
          ours or others rights, property or safety.
        </p>
        <h4>Third Party Services</h4>
        <p>
          We may link to, include or offer third party products or services on or through our web site.
          If you choose to use any such third party services, we may facilitate sharing of your
          information and documents you choose to use with those services. Your use of those services is
          not governed by our Privacy Policy. Be sure to review the terms and conditions and privacy
          policies of those third parties before using their services.
        </p>
        <h4>Reviewing or Changing Personal Information </h4>
        <p>
          You can review or request changes to your Personal Information by logging into your account at
          www.departmentware.com
        </p>
        <h4>Changes to our Privacy Policy</h4>
        <p>
          If we change our privacy policy, we will post those changes on this page and update the Last
          Updated date above.
        </p>
        <br/>
        <br/>
        <br/>

      </Container>
    </div>
  )
;

export default PrivacyPolicy;
