export default {
  PUSH_TASK: 'PUSH_TASK',
  PUSH_TASKS: 'PUSH_TASKS',
  REMOVE_TASK: 'REMOVE_TASK',
  REPLACE_TASK: 'REPLACE_TASK',
  SET_IS_ADDING_TASK: 'SET_IS_ADDING_TASK',
  SET_IS_EDITING_TASK: 'SET_IS_EDITING_TASK',
  SET_TASK_IS_LOADING: 'SET_TASK_IS_LOADING',
  RESET_TASK_STATE: 'RESET_TASK_STATE',
};
