import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

const PasswordResetContainer = () => {
  return (
    <Fragment>
      <Route component={ForgotPassword} exact path="/forgot-password" />
      <Route component={ResetPassword} path="/forgot-password/:email/reset/:verificationCode" />
    </Fragment>
  );
};

export default withRouter(PasswordResetContainer);
