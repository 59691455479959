import commonActionCreators from '../../../shared/store/common/action-creators';
import publicCommitTypes from './commit-types';
import ApiService from '../../../shared/services/api-service';

const publicActions = {
  createDemoRequest(formData) {
    const demoRequest = {
      demo_request: {
        ...formData,
      },
    };

    return (dispatch) => {
      return ApiService.post('/api/v1/demo-request', demoRequest)
        .then(response =>
        {
          dispatch(this.createDemoRequestSuccess(response.data))
        })
        .catch(error => {
          dispatch(commonActionCreators.handleApiError(error))
        });
    };
  },
  createDemoRequestSuccess(demoRequest) {
    return {
      demoRequest,
      type: publicCommitTypes.COMMIT_DEMO_REQUEST_SUCCESS,
    };
  },
  resetPublicState() {
    return {
      type: publicCommitTypes.RESET_PUBLIC_STATE,
    };
  },
};

export default publicActions;
