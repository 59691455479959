import React from 'react';
import './BrandHeader.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const BrandHeader = ({dark}) => (

  <a className={classNames('brand-header', {dark})} href="https://departmentware.com">
    <img src="/images/logo.png" alt="Departmentware Logo" />
    <div className="brandName">DEPARTMENTWARE
      <span> Better software for public safety </span>
    </div>
  </a>

);

 export default BrandHeader;
