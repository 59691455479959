import userContextModel from '../../models/user-context';
import ReducerUtilities from '../reducer-utilities';
import commonCommitTypes from './commit-types';

const INITIAL_COMMON_STATE = {
  apiErrors: null,
  isLoading: [],
  redirectingTo: null,
  userContext: userContextModel(),
  formTypes: []
};

function commonReducers(state = INITIAL_COMMON_STATE, action) {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case commonCommitTypes.CLEAR_API_ERROR:
      return {
        ...state,
        apiErrors:
          state.apiErrors.length > 1 ? state.apiErrors.slice(0, 1) : null,
      };
    case commonCommitTypes.PUSH_API_ERROR:
      return {
        ...state,
        apiErrors:
          state.apiErrors === null
            ? [action.apiError]
            : [...state.apiErrors, action.apiError],
      };
    case commonCommitTypes.RESET_COMMON_STATE:
      return { ...INITIAL_COMMON_STATE };
    case commonCommitTypes.SET_COMMON_IS_LOADING:
      return {
        ...state,
        isLoading: ReducerUtilities.updateIsLoading(action.isLoading, state.isLoading),
      };
    case commonCommitTypes.SET_REDIRECTING_TO:
      return {
        ...state,
        redirectingTo: action.redirectingTo,
      };
    case commonCommitTypes.SET_USER_CONTEXT:
      return {
        ...state,
        userContext: userContextModel(action.userContext),
      };
    case commonCommitTypes.FETCH_FORM_TYPES:
      return {
        ...state,
        formTypes: action.form_types
      }
    default:
      return state;
  }
}

export default commonReducers;
