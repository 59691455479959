export default {
  PUSH_NOTIFICATIONS: 'PUSH_NOTIFICATIONS',
  REPLACE_NOTIFICATION: 'REPLACE_NOTIFICATION',
  RESET_DEPARTMENT_SETTINGS_STATE: 'RESET_DEPARTMENT_SETTINGS_STATE',
  SET_DEPARTMENT_SETTINGS: 'SET_DEPARTMENT_SETTINGS',
  SET_DEPARTMENT_SETTINGS_IS_LOADING: 'SET_DEPARTMENT_SETTINGS_IS_LOADING',
  SET_IS_EDITING_NOTIFICATION: 'SET_IS_EDITING_NOTIFICATION',
  SET_SELECTED_NOTIFICATION: 'SET_SELECTED_NOTIFICATION',
  SET_IS_ADDING_TASK: 'SET_IS_ADDING_TASK',
  PUSH_TASKS : 'PUSH_TASKS'
};
