import {initSharedStorage} from "./shared-storage";

let authResolve;

let AmplifyAuth = new Promise((resolve) => {
  authResolve = resolve
});

let Auth, Storage;
const initAmplify = async (config) => {
  await initSharedStorage();
  const Amplify = await import('aws-amplify');

  Amplify.default.configure(config);
  Auth = Amplify.Auth;
  authResolve(Auth);
  Storage = Amplify.Storage;
};

const getAmplifyStorage = async () => {
  const Amplify = await import('aws-amplify');
  return Amplify.Storage
};

export {
  initAmplify,
  AmplifyAuth,
  getAmplifyStorage,
  Auth,
  Storage
}
